import request from '../utils/request'
 /*
 * ============新增账户
 * @params{
 *
 * addAccountVo:{
 *      bankaddress: string      开户行
 *      bankname:string          银行名称
 *      name:string              姓名
 *      no:string                账号
 *      type:init                充值类型
 *      status:init              状态
 *     }
 * }
 * */
 //查询
export function subdivisionData(data) {
    return request({
        url:'/platform/food/subdivision/data',
        method:'get',
        data
    })
}
//分销log查询
export function logoData(data) {
    return request({
        url:'/platform/food/log/data',
        method:'get',
        data
    })
}
//分销配置
export function configData(data) {
    return request({
        url:'/platform/food/config/data',
        method:'get',
        data
    })
}
//新增产品分销
export function addDo(data) {
    return request({
        url:'/platform/food/config/addDo',
        method:'post',
        data
    })
}
//修改产品分销
export function editDo(data) {
    return request({
        url:'/platform/food/config/editDo',
        method:'post',
        data
    })
}
//删除
export function configDelete(data) {
    return request({
        url:'/platform/food/config/delete',
        method:'post',
        data
    })
}
/*
*==============账户列表
* @params{
* searchAccountVo:{    查询条件
*   name:string    姓名
*   no:string      账户
*   status:init    状态
*   type:init      类型
* }
* searchByPageVo:{
*    page:init
*    rows:init
* }
* */

/*
* ============更新账户
* @params{
* updateAccountVo:{
*   bankaddress: string      开户行
*   bankname:string          银行名称
*   name:string              姓名
*   no:string                账号
*   type:init                充值类型
*   status:init              状态
*   }
* }
* */

