<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input placeholder="请输入内容" v-model="query.searchKeyword" @keyup.enter.native="doSearch">
                            <el-select v-model="query.searchName" slot="prepend" placeholder="查询类型" style="width: 120px;">
                                <el-option label="名称" value="goods_name"></el-option>
                                <el-option label="id" value="goods_id"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <el-row class="row-btn-group">
                <el-button type="primary" @click="handleAdd('新增')">
                    <i class="cw-icon cw-icon-tianjia"></i>新增
                </el-button>
<!--                <el-button type="danger" @click="doDelMore" :disabled="selectData.length==0">-->
<!--                    <i class="cw-icon cw-icon-tianjia"></i>删除选中-->
<!--                </el-button>-->
            </el-row>
            <el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div class="one" v-for="(item,index) in props.row.subdivision_data" :key="index">
                            <span>{{item.level}}</span>级返点<span>{{item.runPoints}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        label="商品 ID"
                        prop="goodsid">
                </el-table-column>
                <el-table-column
                        label="商品名称"
                        prop="goods_name">
                </el-table-column>
                <el-table-column
                        label="价格"
                        prop="price">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改</el-button>
                        <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('editForm')" :title="editTitle" :dialogVisible="visibleFormDialog" v-if="visibleFormDialog">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="95px">
                    <el-form-item prop="goodsId" label="商品">
                        <el-select v-model="formData.goodsId" placeholder="商品名称" style="width: 100%;">
                            <el-option v-for="(item,index) in goodsDataArr" :key="index" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="price" label="价格">
                        <el-input maxlength="100" placeholder="价格"
                                  v-model="formData.price"
                                  auto-complete="off" tabindex="3" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="subdivisionData" label="返点">
                        <el-input v-for="(item,index) in formData.subdivisionData"
                                  :key="index"
                                  v-model="item.runPoints"
                                  :placeholder="item.level+'级返点(小于1的百分点)'"
                                  style="margin-bottom: 10px"
                        >
                            <template slot="prepend">{{item.level}}级</template>
                        </el-input>

                    </el-form-item>

                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                        <el-button @click="closeModul">取 消</el-button>
                    </div>
                </el-form>

            </template>
        </form-dialog>
    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import formDialog from '../../components/formDialog'
  import {shopData,shopAdd,shopEdit,shopDeleteOne,shopDeleteMore,getErcode} from '../../api/firmManage'
  import {subdivisionData,logoData,configData,addDo,editDo,configDelete} from '../../api/distributeManage'
  import {goodsData} from "../../api/foodManager";
  import moment from 'moment'
  export default {
    name: "companyManagement",
    components: {formDialog},
    data() {
      let validatesubdivisionData = (rule, value, callback) => {
        console.log(205,value)
        if (!value[0].runPoints||!value[1].runPoints) {
          callback(new Error('至少填写至1级'));
        }else if(value[0].runPoints>1||value[1].runPoints>1||value[2].runPoints>1||value[3].runPoints>1){
          callback(new Error('返点应小于销售金额'));
        } else {
          callback();
        }
      };
      return {
        tableData: [],
        goodsDataArr:[],
        input1:'',
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
        },
        query: {
          searchName: '',
          searchKeyword: ''
        },
        visibleFormDialog:false,
        formData: {
          goodsId:'',
          price:'',
          subdivisionData:[
            {"level":"0",'runPoints':''},
            {"level":"1",'runPoints':''},
            {"level":"2",'runPoints':''},
            {"level":"3",'runPoints':''},
          ],
        },
        editTitle:'',
        formRules: {
          goodsId: [
            {required: true, message: '商品名称', trigger: 'blur'}
          ],
          price: [
            {required: true, message: '价格', trigger: 'blur'}
          ],
          subdivisionData:[
            {required: true,validator:validatesubdivisionData, trigger: 'blur'}
          ]
        },
        selectData:[],
        codeDialogVisible:false,
        shopErcode: {},
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
    },
    methods: {
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.configData()
      },
      async configData() {
        try {
          let obj=this.query.searchName=='goods_name'?{'goods_name':this.query.searchKeyword}:{"goods_id":this.query.searchKeyword};
          let query = Object.assign({}, this.pageInfo, obj)
          let formData = {}
          for (let i in query) {
            if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
              formData[i] = query[i]
            }
          }
          let resonse = await configData(formData)
          let {data} = resonse;
          console.log(392,data);
          data.list.forEach(curr=>{
            curr.subdivision_data=eval(curr.subdivision_data);
          })
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e)
        }
      },


      async goodsData(){
        try {
          let {data}=await goodsData();
          console.log(340,data);
          this.goodsDataArr=data.list;
        }catch (e) {

        }
      },
      clearSearch() {
        this.query={
          searchName: '',
          searchKeyword: ''
        },
          this.handleSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.page = 1
        this.configData()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.configData()
      },
      handleClose(form) {
        this.$refs[form].resetFields();
        this.visibleFormDialog = false;
        this.codeDialogVisible=false;
      },
      handleAdd() {
        this.editTitle = '新建';
        this.visibleFormDialog = true;
        this.formData={
          goodsId:'',
          price:'',
          subdivisionData:[
            {"level":"0",'runPoints':''},
            {"level":"1",'runPoints':''},
            {"level":"2",'runPoints':''},
            {"level":"3",'runPoints':''},
          ],
        },
        this.$refs.searchForm.resetFields();
      },
      handleEdit(row) {
        this.editTitle = '修改';
        let len=row.subdivision_data.length;
        for(let i=len;i<4;i++){
          row.subdivision_data.push({
            "level":i,'runPoints':''
          })
        }
        // console.log(280,row.subdivision_data);
        row.subdivisionData=row.subdivision_data;
        row.goodsId=row.goodsid;
        this.formData=row;
        console.log(435,this.formData)
        this.visibleFormDialog = true;
      },
      //修改新增
      async doEdit(){
        console.log(271,this.formData);
        this.$refs['editForm'].validate(async (valid)=>{
          if(valid){
            try {
              let request=JSON.parse(JSON.stringify(this.formData));
              request.subdivisionData=request.subdivisionData.filter(curr=>curr.runPoints!='');
              // console.log(343,request.subdivisionData)
              request.subdivisionData=JSON.stringify(request.subdivisionData);

              // this.formData.subdivisionData=JSON.stringify(this.formData.subdivisionData);
              this.formData.id?await editDo(request):await addDo(request);
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.visibleFormDialog = false;
              this.$refs['editForm'].resetFields();
              this.configData()
            }catch (e) {

            }
          }
        })
      },
      //删除
      async doDelete(row){
        this.$confirm('删除后无法恢复，确定删除'+row.goods_name+'的分销吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            let request=JSON.stringify([row.id]);
            await configDelete({ids:request});
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.configData()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      closeModul(){
        this.visibleFormDialog = false;
        this.configData();
      },
      async doDelMore(){
        let ids = [];
        this.selectData.forEach(function (val) {
          ids.push(val.id);
        });
        this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await shopDeleteMore({ids: ids.toString()});
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.configData()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
    },
    mounted() {
      this.configData();
      this.goodsData()
    }
  }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix{
        left: 15px;
        color: #303133;
        i{
            font-style:normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
